/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponseTransformer,
  AxiosRequestTransformer,
} from 'axios'
import humps from 'humps'
import qs from 'qs'
import { getAuthStore, showNotification } from 'src/utils/functions'

const apiHost = import.meta.env.VITE_API_BASE_URL as string | undefined
let controller = new AbortController()

const api: AxiosInstance = axios.create({
  baseURL: apiHost,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) =>
    qs.stringify(humps.decamelizeKeys(params), { arrayFormat: 'brackets' }),
  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
    (data) => (data instanceof Blob ? data : humps.camelizeKeys(data)),
  ],
  transformRequest: [
    (data) => (data instanceof FormData ? data : humps.decamelizeKeys(data)),
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
  signal: controller.signal
})

async function checkToken(config: AxiosRequestConfig) {
  const authStore = getAuthStore()
  const { token, tokenExp, refreshToken } = authStore.tokenData

  if (token) {
    if (tokenExp && tokenExp * 1000 < +new Date()) {
      try {
        const res = await axios({
          ...config,
          method: 'post',
          url: 'sessions/refresh_token',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
            Refresh: refreshToken || '',
          },
          params: {}
        })

        authStore.setToken(res.data)
        config.headers!.Authorization = 'Bearer ' + res.data.token
        if (config.url === 'sessions' && config.method === 'delete') {
          config.headers!.Refresh = res.data.tokenExp
        }
      } catch (error: any) {
        // to skip when this request was aborted
        if (error.code !== 'ERR_CANCELED') {
          controller.abort()
          controller = new AbortController()
          getAuthStore().signOut()
          showNotification('error', 'Twoja sesja wygasła. Zaloguj się ponownie.')
        }
      }
    } else {
      config.headers!.Authorization = 'Bearer ' + token
      if (config.url === 'sessions' && config.method === 'delete') {
        config.headers!.Refresh = refreshToken || ''
      }
    }
  }
  return config
}

api.interceptors.request.use(
  (config) => {
    return checkToken({ ...config, signal: controller.signal })
  },
  (error) => {
    Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => {
    const blobTypes = ['application/zip', 'text/csv'] // Quick Fix for Export CSV
    if (blobTypes.includes(response.headers['content-type'])) {
      return response
    }
    return response
  },
  (error) => {
    if (error.code === 'ERR_CANCELED') return
    const { data, status } = error.response
    let message
    const authStore = getAuthStore()
    const preventMsgCodes = [
      'validate_scheduled_appointments_existence',
      'validate_headers_token',
      'validate_headers_refresh_token',
      'fetch_user',
      'fetch_whitelisted_token'
    ]
    switch (status) {
      case 401:
        authStore.signOut()
        message = 'Twoja sesja wygasła. Zaloguj się ponownie.'
        break
      case 403:
      case 404:
      case 422:
        message = data.error.message || 'Wystąpił błąd.'
        if (data.error.code === 'validation_error') {
          interface ErrorType {
            code: string
            path: string[]
            message: string
          }

          message =
            data.error.details
                .map((item: any): ErrorType => item.message)
                .join('<br/>') || 'Wystąpił błąd.'
        } else if (preventMsgCodes.includes(data.error.code)) {
          message = ''
        }
        break
      default:
        console.log(data.error)
        message = 'Coś poszło nie tak, spróbuj ponownie.'
    }
    if (message === 'Refresh token nieprawidłowy') message = 'Twoja sesja wygasła. Zaloguj się ponownie.'
    if (message) showNotification('error', message)
    return Promise.reject(data.error)
  }
)

export { api }
