import { defineStore } from 'pinia'
import cartApi from '../../api/cart'
import {
  Cart,
  RequestCartItem,
  AvailableSamplingPoints,
  CartInvoiceData,
  OrderSummary,
  DeliveryData,
} from '../../types/cart'
import { Notify, Dialog, date } from 'quasar'
import {
  areCartAppointmentsJoined,
  getBoundaryDates,
  isExecMethodDifferent,
  parseWeekday,
  showNotification,
} from '../../utils/functions'
import CustomDialog from 'src/components/elements/CustomDialog.vue'
import { CommerceExamination, ExecutionMethod } from 'src/types/examinations'

export const useCartStore = defineStore('cart', {
  state: () => ({
    cart: <Cart>{},
    samplingPoints: [],
    timeSlots: <{ [x: number]: string[] }>{},
    dateRange: new Date().toISOString().substring(0, 10),
    loading: false,
    isInvoiced: false,
    invoice: <CartInvoiceData>{ address: { countryCode: 'PL' } },
    orderSummary: <OrderSummary>{},
    checkedConsentIds: <number[]>[],
    cartExecutionMethod: undefined as ExecutionMethod | undefined,
    deliveryDetails: <DeliveryData>{
      outgoingShipment: {},
      incomingShipment: {}
    }
  }),
  actions: {
    getCart() {
      this.loading = true
      return cartApi
        .getCart()
        .then((res) => {
          this.setCart(res.data)
        })
        .finally(() => {
          this.loading = false
        })
    },
    setCart(cart: Cart) {
      this.cart = cart
      if (cart.cartItems?.length) {
        document.cookie = `INN-CUSTOMER-cart-items=${cart.cartItems.length.toString()}; path=/; domain=${import.meta.env.VITE_APP_DOMAIN}`
        this.cartExecutionMethod = cart.cartItems[0].examination.executionMethod.value as ExecutionMethod;
      } else {
        document.cookie = `INN-CUSTOMER-cart-items=; path=/; domain=${import.meta.env.VITE_APP_DOMAIN}`
        this.cartExecutionMethod = undefined
      }
    },
    addItemToCart(newExamination: CommerceExamination) {
      const { id, executionMethod } = newExamination
      const addItem = () => {
        this.loading = true
        return cartApi
          .addItemToCart(id)
          .then((res) => {
            localStorage.setItem('INN-cart-token', res.data.token)
            this.setCart(res.data)
            Notify.create({
              message: 'Dodano do koszyka',
              icon: 'check_circle_outline',
              color: 'success-60',
              textColor: 'success-80',
              position: 'top',
              badgeColor: 'additional2-60',
              actions: [
                {
                  label: 'Pokaż koszyk',
                  color: 'white',
                  padding: '8px 16px',
                  handler: () => {
                    this.router.push({ name: 'cart' })
                  },
                },
              ],
            })
          })
          .finally(() => {
            this.loading = false
          })
      }

      if (isExecMethodDifferent(this.cart, executionMethod.value as ExecutionMethod)) {
        Dialog.create({
          component: CustomDialog,
          componentProps: {
            showCancel: true,
            okLabel: 'Dodaj',
            title: 'Czy na pewno chcesz dodać to badanie do koszyka?',
            message:
              'Spowoduje to nadpisanie tego co aktualnie się w nim znajduje.',
          },
        }).onOk(() => {
          return new Promise((resolve) => {
            this.emptyCart().then(() => {
              addItem().then(() => resolve(true))
            })
          })
        })
      } else if (executionMethod.value === 'at_sampling_point' && areCartAppointmentsJoined(this.cart, true)) {
        Dialog.create({
          component: CustomDialog,
          componentProps: {
            showCancel: true,
            okLabel: 'Dodaj',
            title: 'Czy na pewno chcesz dodać kolejne badanie do koszyka?',
            message:
              'Spowoduje to usunięcie wybranej daty i lokalizacji dla pozostałych badań.',
          },
        }).onOk(() => {
          const updatedCartItems = this.cart.cartItems.map((item) => ({
            id: item.id,
            examinationDate: null,
            samplingPointId: null,
          }))
          return new Promise((resolve) => {
            this.updateCartAppointments({
              editedItems: updatedCartItems,
            }, true).then(() => {
              addItem().then(() => resolve(true))
            })
          })
        })
      } else {
        return addItem()
      }
    },
    deleteCartItem(id: number) {
      this.loading = true
      return cartApi
        .deleteCartItem(id)
        .then((res) => {
          this.setCart(res.data)
          showNotification('success', 'Usunięto z koszyka')
        })
        .finally(() => {
          this.loading = false
        })
    },
    emptyCart() {
      this.loading = true
      return cartApi
        .clearCart()
        .then((res) => {
          this.setCart(res.data)
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateCartAppointments({
      editedItems,
      identityId,
    }: {
      editedItems?: RequestCartItem[]
      identityId?: number
    }, hideSuccessMsg = false) {
      const cartItems = this.cart.cartItems.map((item) => {
        if (editedItems?.length) {
          const newElement = editedItems?.find(
            (editedItem) => editedItem.id === item.id
          )
          if (newElement) return newElement
        }
        return {
          id: item.id,
          samplingPointId: item.samplingPoint?.id || null,
          examinationDate: item.examinationDate,
        }
      })
      const params = {
        // cart update is disabled if identity is not provided
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        identityId: identityId || this.cart.identity!.id,
        cartItems,
      }
      this.loading = true
      return cartApi
        .updateCart(params)
        .then((res) => {
          this.setCart(res.data)
          if (!hideSuccessMsg) showNotification('success', 'Zaktualizowano koszyk')
        })
        .finally(() => {
          this.loading = false
        })
    },
    submitOrder() {
      this.loading = true
      const params = {
        companyInvoiceData: this.isInvoiced ? this.invoice : undefined,
        checkedConsentIds: this.checkedConsentIds,
        cartItems: this.cart.cartItems.map((item) => ({
          price: item.examination.price,
          id: item.id,
        })),
        delivery: this.cartExecutionMethod === 'at_home' ? this.deliveryDetails : undefined,
      }
      return cartApi
        .submitOrder(params)
        .then((res) => {
          showNotification('success', 'Zamówienie zostało złożone')
          window.location.href = res.data.paymentUrl
        })
        .finally(() => {
          this.loading = false
        })
    },
    getAvailableSamplingPoints(params: AvailableSamplingPoints) {
      this.loading = true
      return cartApi
        .getAvailableSamplingPoints(params)
        .then((res) => {
          this.samplingPoints = res.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    getAvailableTimeSlots(samplingPointId: number, examinationId?: number) {
      const { start, end } = getBoundaryDates(this.dateRange)
      const params = {
        samplingPointId,
        examinationId,
        dateRange: {
          dateFrom: start.toISOString().substring(0, 10),
          dateTo: end.toISOString().substring(0, 10),
        },
      }
      this.loading = true
      return cartApi
        .getAvailableTimeSlots(params)
        .then((res) => {
          this.timeSlots = res.data[0]
          // BE puts respons inside array for some reason
        })
        .finally(() => {
          this.loading = false
        })
    },
    getOrderSummary(transactionId: string) {
      this.loading = true
      return cartApi
        .getOrderSummary({ transactionId })
        .then((res) => {
          this.orderSummary = res.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    retryPayment() {
      this.loading = true
      return cartApi
        .retryPayment(this.orderSummary.id)
        .then((res) => {
          window.location.href = res.data.paymentUrl
        })
        .finally(() => {
          this.loading = false
        })
    },
    resetTimeSlots() {
      this.timeSlots = {}
    },
    resetCart() {
      this.cart = <Cart>{}
      this.cartExecutionMethod = undefined
      document.cookie = `INN-CUSTOMER-cart-items=; path=/; domain=${import.meta.env.VITE_APP_DOMAIN}`
    },
  },
  getters: {
    parsedTimeSlots() {
      const options = new Array(5).fill(null)

      for (const dayNumber in this.timeSlots) {
        // monday is 1 but need that at 0 index
        const index = Number(dayNumber) - 1
        options[index] = this.timeSlots[dayNumber]
      }
      const { start } = getBoundaryDates(this.dateRange)

      return options.map((option, i) => {
        const currentDay = date.addToDate(start, { days: i })
        return {
          label: parseWeekday(currentDay, 'dddd, D MMMM'),
          shortLabel: parseWeekday(currentDay, 'dddd, D MMMM', true),
          values: option
            ? option.map((datetime: Date) => ({
              label: date.formatDate(datetime, 'HH:mm'),
              value: datetime,
            }))
            : [],
        }
      })
    },
    isShipmentDataValid(): { outgoing: boolean, incoming: boolean } {
      return {
        outgoing: Boolean(this.deliveryDetails.outgoingShipment.phoneNumber) &&
                  (Boolean(this.deliveryDetails.outgoingShipment.deliveryPoint?.name) ||
                   Boolean(this.deliveryDetails.outgoingShipment.address?.addressStreet)),
        incoming: Boolean(this.deliveryDetails.incomingShipment.shippingMethodId)
      }
    }
  },
})
