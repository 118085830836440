import { defineStore } from 'pinia'
import sessionsApi from '../../api/sessions'
import usersApi from '../../api/users'
import totpApi from '../../api/totp'
import {
  AdminSignInForm,
  AdminUser,
  AuthPasswordForm,
  AuthTokens,
} from '../../types/auth'
import storageVars from '../../const/storageVariables'
import { showNotification } from 'src/utils/functions'

interface AdminTokens extends AuthTokens {
  user: AdminUser
}

export const useAdminAuthStore = defineStore('admin-auth', {
  state: () => ({
    user:
      JSON.parse(localStorage.getItem('saved-account') as string) || <AdminUser>{},
    tokenData: <AdminTokens>{},
    loading: false,
  }),
  getters: {
    isLoggedIn: (state) => !!state.tokenData.token,
  },
  actions: {
    setToken(tokenData: AdminTokens) {
      this.tokenData = tokenData
      sessionStorage.setItem(storageVars.auth, JSON.stringify(tokenData))
      if (!this.user.id) {
        const userData = JSON.parse(localStorage.getItem('saved-account') as string) || tokenData.user
        if (userData.id) this.user = userData; else this.signOut()
      }
    },
    clearAuthSession() {
      this.tokenData = <AdminTokens>{}
      sessionStorage.removeItem(storageVars.auth)
      localStorage.removeItem('saved-account')
    },
    authorize(form: AdminSignInForm) {
      this.loading = true
      return new Promise((resolve) => {
        sessionsApi
          .signIn({
            ...form,
            formUserType: 'Administrator',
          })
          .then((res) => {
            const { user, ...tokens } = res.data
            localStorage.setItem('saved-account', JSON.stringify(user) as string)
            this.user = user
            this.tokenData = tokens
            resolve(res.data.user.profile.totpEnabled)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    enableTotp(totpAttemp: string) {
      this.loading = false
      return totpApi
        .enableTotp(totpAttemp)
        .then((res) => {
          this.setToken(res.data)
          this.router.push({ name: 'home' })
        })
        .finally(() => {
          this.loading = false
        })
    },
    validateTotp(totpAttemp: string) {
      this.loading = false
      return totpApi
        .validateTotp(totpAttemp)
        .then((res) => {
          this.setToken(res.data)
          this.router.push({ name: 'home' })
        })
        .finally(() => {
          this.loading = false
        })
    },
    setPassword(form: AuthPasswordForm) {
      this.loading = true
      return usersApi
        .setPassword(form)
        .then(() => {
          this.router.push({ name: 'login' })
          showNotification('success', 'Poprawnie zmieniono hasło. Możesz zalogować się nowymi poświadczeniami.')
        })
        .finally(() => {
          this.loading = false
        })
    },
    resetPassword(email: string) {
      this.loading = true
      return usersApi
        .resetPassword({ email })
        .then(() => {
          showNotification('success', 'Na podany e-mail wysłano link do resetu hasła.')
        })
        .finally(() => {
          this.loading = false
        })
    },
    signOut() {
      sessionsApi.signOut().finally(() => {
        this.clearAuthSession()
        this.router.push({ name: 'login' })
      })
    },
  },
})
