import { api } from 'boot/axios'
import { AdminSignInForm, AuthSignInForm } from '../types/auth'

export default {
  signIn(params: AdminSignInForm | AuthSignInForm) {
    return api.post('sessions', params, {
      headers: {
        'X-Order-Token': localStorage.getItem('INN-cart-token') as string,
        // X-Order-Token is being used here in order to merge not logged in user's cart with it's existing one
      },
    })
  },
  signOut() {
    return api.delete('sessions')
  },
}
