import { defineStore } from 'pinia'
import { Dialog } from 'quasar'
import CustomDialog from 'components/elements/CustomDialog.vue'
import customersApi from '../../api/customers'
import usersApi from '../../api/users'
import sessionsApi from '../../api/sessions'
import validationsApi from '../../api/validations'
import {
  AuthUser,
  AuthTokens,
  AuthPasswordForm,
  AuthRegister,
  AuthIdentity,
  CustomerUser,
  UpdateUserForm,
  ChangePasswordForm,
} from 'src/types/auth'
import storageVars from '../../const/storageVariables'
import { showNotification } from 'src/utils/functions'
import { useCartStore } from './cart-store'

interface CustomerTokens extends AuthTokens {
  user: CustomerUser
}

export const useCustomerAuthStore = defineStore('customer-auth', {
  state: () => ({
    user: <AuthUser>{},
    tokenData: <CustomerTokens>{},
    loading: false,
  }),
  getters: {
    isLoggedIn: (state) => !!state.tokenData.token,
  },
  actions: {
    async signIn(form: {
      email: string
      password: string
      rememberAuth: boolean
    }) {
      this.loading = true
      const params = {
        email: form.email,
        password: form.password,
        formUserType: 'Customer',
      }
      try {
        const response = await sessionsApi.signIn(params)
        this.tokenData = response.data
        if (form.rememberAuth) {
          localStorage.setItem(storageVars.remember, 'true')
          localStorage.setItem(storageVars.auth, JSON.stringify(this.tokenData))
        } else {
          sessionStorage.setItem(
            storageVars.auth,
            JSON.stringify(this.tokenData)
          )
        }
        const { firstName, lastName } = response.data.user.profile
        const initials = `${firstName[0]}${lastName[0]}`

        document.cookie = `INN-CUSTOMER-initials=${initials}; path=/; domain=${
          import.meta.env.VITE_APP_DOMAIN
        }`
        localStorage.removeItem('INN-cart-token')
        await this.fetchUser()
        this.router.push({ name: 'home' })
      } finally {
        this.loading = false
      }
    },
    fetchUser() {
      return customersApi.fetchUser().then((res) => {
        this.user = res.data
      })
    },
    setToken(tokenData: CustomerTokens) {
      this.tokenData = tokenData
      if (localStorage.getItem(storageVars.remember)) {
        localStorage.setItem(storageVars.auth, JSON.stringify(tokenData))
      } else {
        sessionStorage.setItem(storageVars.auth, JSON.stringify(tokenData))
      }
    },
    clearAuthSession() {
      this.user = <AuthUser>{}
      this.tokenData = <CustomerTokens>{}
      localStorage.removeItem(storageVars.auth)
      localStorage.removeItem(storageVars.remember)
      sessionStorage.removeItem(storageVars.auth)
      document.cookie = `INN-CUSTOMER-initials=; path=/; domain=${
        import.meta.env.VITE_APP_DOMAIN
      }`
    },
    signOut() {
      return sessionsApi.signOut().finally(() => {
        this.clearAuthSession()
        const cartStore = useCartStore()
        cartStore.resetCart()
        this.router.push({ name: 'login' })
      })
    },
    registerUser(data: AuthRegister) {
      this.loading = true
      customersApi
        .createCustomer(data)
        .then(() => {
          Dialog.create({
            component: CustomDialog,
            componentProps: {
              title: 'Dziękujemy za założenie konta w Inngen',
              message:
                'Na twojego maila wysłaliśmy link aktywujący. Kliknij w niego, aktywuj swoje konto i zacznij zamawiać badania.',
            },
          }).onDismiss(() => {
            this.router.push({ name: 'login' })
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    checkRegisterData(
      email: string,
      identityValue: string,
      identityType: string,
      gender: string,
      birthday: string,
      phoneNumber: string
    ) {
      return new Promise((resolve, reject) => {
        validationsApi
          .validateEmail(email)
          .then(() => {
            validationsApi
              .validateIdentity(identityValue, identityType, gender, birthday)
              .then(() => {
                validationsApi
                  .validatePhone(phoneNumber)
                  .then(() => {
                    resolve(true)
                  })
                  .catch(() => {
                    reject()
                  })
              })
              .catch(() => {
                reject()
              })
          })
          .catch(() => {
            reject()
          })
      })
    },
    confirmAccount(token: string) {
      return customersApi.confirmCustomer(token).then(() => {
        showNotification(
          'success',
          'Twój e-mail został potwierdzony! Witaj w Inngen'
        )
      })
    },
    resetPassword(email: string) {
      this.loading = true
      return usersApi
        .resetPassword({ email })
        .then(() => {
          showNotification(
            'success',
            'Na podany e-mail wysłano link do resetu hasła.'
          )
        })
        .finally(() => {
          this.loading = false
        })
    },
    setPassword(form: AuthPasswordForm) {
      this.loading = true
      return usersApi
        .setPassword(form)
        .then(() => {
          this.router.push({ name: 'login' })
          showNotification(
            'success',
            'Poprawnie zmieniono hasło. Możesz zalogować się nowymi poświadczeniami.'
          )
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateUser(form: UpdateUserForm) {
      return customersApi.updateUser(form).then((res) => {
        this.user = res.data
      })
    },
    updatePassword(form: ChangePasswordForm) {
      return customersApi.changePassword(form)
    },
    createChild(form: Omit<AuthIdentity, 'id'>) {
      return customersApi.createChild(form).then((res) => {
        this.user.children?.push(res.data)
      })
    },
    deactivateAccount(id?: number) {
      return customersApi.deactivateAccount(id).then(() => {
        if (id && this.user.children) {
          this.user.children = this.user.children.filter(
            (child) => child.identity.id !== id
          )
        }
      })
    },
  },
})
