<script setup lang="ts">
import { useDialogPluginComponent } from 'quasar'

interface Props {
  title?: string
  message?: string
  okLabel?: string
  showCancel?: boolean
  cancelLabel?: string
  persistent?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  message: '',
  okLabel: 'Ok',
  showCancel: false,
  cancelLabel: 'Anuluj',
  persistent: false,
})

defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent()
// dialogRef      - Vue ref to be applied to QDialog
// onDialogHide   - Function to be used as handler for @hide on QDialog
// onDialogOK     - Function to call to settle dialog with "ok" outcome
//                  ...and it will also hide the dialog automatically
//                    example: onDialogOK() - no payload
//                    example: onDialogOK({ /*...*/ }) - with payload
// onDialogCancel - Function to call to settle dialog with "cancel" outcome
</script>

<template>
  <q-dialog ref="dialogRef" :persistent="persistent" @hide="onDialogHide">
    <q-card class="q-dialog-plugin q-pa-lg alert-dialog">
      <q-card-section class="q-pa-none">
        <div
          :class="['text-h3 q-mb-sm pr-4', $q.screen.lt.md && 'q-pt-md']"
          style="line-height: 150%"
        >
          {{ props.title }}
        </div>
        <q-space />
        <SvgIcon
          v-close-popup
          name="close"
          color="primary"
          class="close-icon cursor-pointer q-my-md"
          size="25px"
        />
        <div>
          {{ props.message }}
        </div>
      </q-card-section>
      <q-separator inset class="q-my-lg" />
      <q-card-actions align="right" class="q-pa-none">
        <q-btn
          v-if="props.showCancel"
          outline
          color="primary"
          :label="props.cancelLabel"
          no-caps
          padding="12px 16px"
          class="text-button1"
          @click="onDialogCancel"
        />
        <q-btn
          color="primary"
          :label="props.okLabel"
          no-caps
          padding="12px 16px"
          class="text-button1"
          @click="onDialogOK"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style lang="scss" scoped>
.alert-dialog {
  position: relative;
  width: 600px;
  max-width: 80vw;

  .close-icon {
    position: absolute;
    top: -24px;
    right: 0;

    &:hover {
      background-color: $primary-90;
      border-radius: 20px;
    }
  }
}
</style>
