import { LeadOptionItem } from 'src/types/examinations'
import { ValueLabel } from 'src/types/common'
import {
  translations,
  customCountries,
  preferredCountries,
  ignoredCountries,
} from 'src/const/phoneNumberVariables'

export const leadTimeOptions: LeadOptionItem[] = [
  {
    label: '24h',
    value: { value: 24, unit: 'hours', capacity: 'Dzień' }
  },
  {
    label: '48h',
    value: { value: 48, unit: 'hours', capacity: 'Dzień' }
  },
  {
    label: '5 dni',
    value: { value: 5, unit: 'days', capacity: 'Dzień' }
  },
  {
    label: '7 dni',
    value: { value: 7, unit: 'days', capacity: 'Dzień' }
  },
  {
    label: '10 dni',
    value: { value: 10, unit: 'days', capacity: '5 dni' }
  },
  {
    label: '21 dni',
    value: { value: 21, unit: 'days', capacity: '5 dni' }
  },
  {
    label: '1 miesiąc',
    value: { value: 1, unit: 'months', capacity: '5 dni' }
  },
  {
    label: '3 miesiące',
    value: { value: 3, unit: 'months', capacity: '10 dni' }
  },
  {
    label: '6 miesiący',
    value: { value: 6, unit: 'months', capacity: '10 dni' }
  },
]

export const toolbarOptions = [
  ['left', 'center', 'right', 'justify'],
  ['bold', 'italic', 'strike', 'underline'],
  ['link', 'removeFormat'],
  ['unordered', 'ordered']
]

export const countriesOptions: ValueLabel[] = preferredCountries.map((iso: string) => {
  return { label: customCountries[iso as keyof typeof customCountries], value: iso }
})

export const inputProps = {
  validatedInput: {
    outlined: true,
    lazyRules: true,
    noErrorIcon: true,
  },
  singleSelect: {
    class: 'select-status',
    inputClass: 'text-primary',
    color: 'primary',
    placeholder: 'Wybierz',
    useInput: true,
    fillInput: true,
    hideSelected: true,
    outlined: true,
    mapOptions: true,
    emitValue: true
  },
  phoneNumber: {
    showCodeOnList: true,
    color: 'primary',
    preferredCountries: preferredCountries,
    ignoredCountries: ignoredCountries,
    translations: translations,
    customCountriesList: customCountries,
    defaultCountryCode: 'PL',
  },
  selectCountry: {
    useInput: true,
    outlined: true,
    inputDebounce: '300',
    color: 'primary',
    inputClass: 'text-primary',
    placeholder: 'Wybierz kraj',
    optionDisable: 'inactive',
    fillInput: true,
    hideSelected: true,
    mapOptions: true,
    emitValue: true,
    lazyRules: true,
    noErrorIcon: true,
  },
  hourPicker: {
    timePicker: true,
    hideInputIcon: true,
    clearable: false,
    placeholder: 'Wybierz godzinę',
    selectText: 'Potwierdź',
    cancelText: 'Anuluj',
    style: 'width: 90px',
  },
  rangePicker: {
    range: true,
    multiCalendars: true,
    locale: 'pl',
    cancelText: 'Anuluj',
    selectText: 'Potwierdź',
    format: 'dd/MM/yyyy',
    enableTimePicker: false,
    partialRange: false,
    placeholder: 'Wybierz zakres'
  },
  datePicker: {
    vertical: true,
    locale: 'pl',
    cancelText: 'Anuluj',
    selectText: 'Potwierdź',
    format: 'dd/MM/yyyy HH:mm',
    clearable: false,
  }
}
