import { defineStore } from 'pinia'
import filtersApi from '../api/filters'
import { StatusOption, ValueLabel } from 'src/types/common'
import { ShippingMethod } from 'src/types/shipment'

interface Filter {
  label: string
  value: string | number
}

interface Filters {
  client: {
    [x: string]: ValueLabel[]
  }
  administrator: {
    [x: string]: ValueLabel[]
  }
}

export const useFiltersStore = defineStore('filters', {
  state: () => ({
    filters: <Filters>{},
    laboratoriesList: <Filter[]>[],
    shippingMethods: <ShippingMethod[]>[],
  }),
  actions: {
    fetchFilters() {
      return filtersApi.getFilters().then((res) => {
        this.filters = res.data
      })
    },
    fetchLaboratories() {
      return filtersApi.getLaboratories().then((res) => {
        this.laboratoriesList = res.data?.map(
          (lab: { id: number; name: string }) => ({
            label: lab.name,
            value: lab.id,
          })
        )
      })
    },
    fetchShippingMethods() {
      return filtersApi.getShippingMethods().then((res) => {
        this.shippingMethods = res.data
      })
    },
  },
})
