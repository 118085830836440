import { defineStore } from 'pinia'
import {
  AuthSignInForm,
  LabUser,
  AuthTokens,
  AuthPasswordForm,
} from '../../types/auth'
import sessionsApi from 'src/api/sessions'
import storageVars from 'src/const/storageVariables'
import usersApi from 'src/api/users'
import { showNotification } from 'src/utils/functions'

interface LabTokens extends AuthTokens {
  user: LabUser
}

export const useLabAuthStore = defineStore('lab-auth', {
  state: () => ({
    user:
      JSON.parse(localStorage.getItem('saved-account') as string) || <LabUser>{},
    tokenData: <LabTokens>{},
    loading: false,
  }),
  getters: {
    isLoggedIn: (state) => !!state.tokenData.token,
    userRole: (state) => state.user.profile?.role || '',
    canDeliver: (state) => state.user.profile?.laboratoryDelivery || false
  },
  actions: {
    setToken(tokenData: LabTokens) {
      this.tokenData = tokenData
      sessionStorage.setItem(storageVars.auth, JSON.stringify(tokenData))
      if (!this.user.id) {
        const user = JSON.parse(localStorage.getItem('saved-account') as string) || tokenData.user
        if (user.id) this.user = user; else this.signOut()
      }
    },
    clearAuthSession() {
      this.tokenData = <LabTokens>{}
      this.user = <LabUser>{}
      sessionStorage.removeItem(storageVars.auth)
      localStorage.removeItem('saved-account')
    },
    authorize(form: AuthSignInForm) {
      this.loading = true
      const params = {
        ...form,
        formUserType: 'Scientist',
      }
      return new Promise((resolve) => {
        sessionsApi
          .signIn(params)
          .then((res) => {
            const { user } = res.data
            localStorage.setItem('saved-account', JSON.stringify(user) as string)
            this.user = user
            this.setToken(res.data)
            this.router.push({ name: 'home' })
            resolve(true)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    setPassword(form: AuthPasswordForm) {
      this.loading = true
      return usersApi
        .setPassword(form)
        .then(() => {
          this.router.push({ name: 'login' })
          showNotification('success', 'Poprawnie zmieniono hasło. Możesz zalogować się nowymi poświadczeniami.')
        })
        .finally(() => {
          this.loading = false
        })
    },
    completeAccount(form: AuthPasswordForm) {
      this.loading = true
      return usersApi
        .completeScientistAccount(form)
        .then(() => {
          this.router.push({ name: 'login' })
          showNotification('success', 'Poprawnie ustawiono hasło. Możesz zalogować się nowymi poświadczeniami.')
        })
        .finally(() => {
          this.loading = false
        })
    },
    signOut() {
      sessionsApi.signOut().finally(() => {
        this.clearAuthSession()
        this.router.push({ name: 'login' })
      })
    },
  }
})
