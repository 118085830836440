import { api } from 'boot/axios'

export default {
  getFilters() {
    return api.get('filters')
  },
  getLaboratories() {
    return api.get('filters/laboratories')
  },
  getShippingMethods() {
    return api.get('shipping_methods')
  },
}
