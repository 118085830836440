import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/logowanie',
    component: () => import('../../layouts/AuthLayout.vue'),
    children: [
      {
        path: ':redirect?',
        component: () => import('pages/customer/auth/AuthLogin.vue'),
        name: 'login',
      },
      {
        path: 'resetuj-haslo',
        name: 'reset-password',
        component: () => import('pages/customer/auth/AuthResetPassword.vue'),
      },
      {
        path: 'nowe-haslo/:token',
        name: 'new-password',
        component: () => import('pages/customer/auth/AuthSetNewPassword.vue'),
      },
    ],
    meta: {
      title: 'Logowanie',
    },
  },
  {
    path: '/potwierdzenie-konta',
    component: () => import('../../layouts/AuthLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/customer/auth/AuthLogin.vue'),
        name: 'confirm',
      },
    ],
    meta: {
      title: 'Potwierdź konto',
    },
  },
  {
    path: '/rejestracja',
    component: () => import('../../layouts/AuthLayout.vue'),
    children: [
      {
        path: '',
        name: 'sign-up',
        component: () => import('pages/customer/auth/AuthSignUp.vue'),
      },
    ],
    meta: {
      title: 'Rejestracja',
    },
  },
  {
    path: '/wizyty',
    component: () => import('layouts/UserLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/customer/user/Appointments.vue'),
        name: 'home',
      },
      {
        path: 'szczegoly/:id',
        component: () => import('pages/customer/user/AppointmentDetails.vue'),
        name: 'appointment-details',
      },
    ],
    meta: {
      title: 'Wizyty',
      requiresAuth: true,
    },
  },
  {
    path: '/testy-wysylkowe',
    component: () => import('layouts/UserLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/customer/user/ShipmentTests.vue'),
        name: 'shipment',
      },
      {
        path: 'szczegoly/:id',
        component: () => import('pages/customer/user/ShipmentTestDetails.vue'),
        name: 'shipment-details',
      },
    ],
    meta: {
      title: 'Testy wysyłkowe',
      requiresAuth: true,
    },
  },
  {
    path: '/zamowienia',
    component: () => import('layouts/UserLayout.vue'),
    children: [
      {
        path: '',
        name: 'orders',
        component: () => import('pages/customer/user/Orders.vue'),
      },
      {
        path: 'szczegoly/:id',
        component: () => import('pages/customer/user/OrderDetails.vue'),
        name: 'order-details',
      },
    ],
    meta: {
      title: 'Zamówienia',
      requiresAuth: true,
    },
  },
  {
    path: '/wyniki',
    component: () => import('layouts/UserLayout.vue'),
    children: [
      {
        path: '',
        name: 'results',
        component: () => import('pages/customer/user/Results.vue'),
      },
    ],
    meta: {
      title: 'Wyniki badań',
      requiresAuth: true,
    },
  },
  {
    path: '/konto',
    component: () => import('layouts/UserLayout.vue'),
    children: [
      {
        path: '',
        name: 'account',
        component: () => import('pages/customer/user/Account.vue'),
      },
    ],
    meta: {
      title: 'Moje konto',
      requiresAuth: true,
    },
  },
  {
    path: '/zamow-badanie',
    component: () => import('../../layouts/CommerceLayout.vue'),
    children: [
      {
        path: '',
        name: 'examinations-list',
        component: () =>
          import('pages/customer/commerce/CommerceExaminationIndex.vue'),
        meta: {
          navTitle: 'Zamów badanie',
        },
      },
      {
        path: ':id/:name?',
        name: 'single-examination',
        component: () =>
          import('pages/customer/commerce/CommerceExaminationSingle.vue'),
        meta: {
          navTitle: 'Szczegóły badania',
        },
      },
      {
        path: 'koszyk',
        name: 'cart',
        component: () => import('pages/customer/commerce/Cart.vue'),
        meta: {
          navTitle: 'Koszyk',
        },
      },
      {
        path: 'potwierdzenie',
        name: 'cart-confirmation',
        component: () => import('pages/customer/commerce/Confirmation.vue'),
        meta: {
          navTitle: 'Potwierdzenie',
        },
      },
    ],
    meta: {
      title: 'Sklep',
    },
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'login' },
    meta: {
      title: '404',
    },
  },
]

export default routes
