import { date, QTableProps, Notify } from 'quasar'
import { Laboratory } from 'src/types/laboratories'
import { SamplingPoint } from 'src/types/sampling-points'
import { Address } from 'src/types/common'
import { Cart, CartItem } from 'src/types/cart'
import { useCustomerAuthStore } from 'src/stores/customer/auth-store'
import { useAdminAuthStore } from 'stores/admin/auth-store'
import { useLabAuthStore } from 'stores/lab/auth-store'
import { weekdayLocale, shortWeekdayLocale } from 'src/const/dateLocale'
import { leadTimeOptions } from 'src/const/formVariables'
import { ExecutionMethod } from 'src/types/examinations'
import { ShipmentDetails, ShippingMethod } from 'src/types/shipment'
import getVideoId from 'get-video-id'

export const getAuthStore = () => {
  switch (import.meta.env.VITE_APP_TYPE) {
    case 'ADMIN':
      return useAdminAuthStore()
    case 'LAB':
      return useLabAuthStore()
    default: // CUSTOMER
      return useCustomerAuthStore()
  }
}

export const formatDate = (rawDate: string | number, withTime?: boolean): string => {
  if (!rawDate) return '-'
  const dateTime = new Date(rawDate)
  let format = 'DD/MM/YYYY'
  if (withTime) format += ' HH:mm'
  return date.formatDate(dateTime, format)
}
export const getInputDate = (rawDate: string) => {
  if (!rawDate) return ''
  return date.formatDate(new Date(rawDate), 'DD.MM.YYYY')
}

export const formatCurrency = (rawCurrency: number | null): string => {
  if (!rawCurrency && typeof rawCurrency !== 'number') return '-'
  return new Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: 'PLN',
  }).format(rawCurrency)
}

export const fillTableConfig = (
  rawConfig: QTableProps['columns']
): QTableProps['columns'] => {
  return rawConfig?.map((column) => ({
    align: 'left',
    format: (value) => value ?? '-',
    ...column,
  }))
}

export const getFullName = (firstName?: string, lastName?: string): string => {
  return `${firstName || ''} ${lastName || ''}`.trim()
}

export const isUnderage = (birthday: string): boolean => {
  const birthDate = new Date(birthday)
  const legalAgeDate = date.subtractFromDate(new Date(), { years: 18 })
  return date.isBetweenDates(birthDate, legalAgeDate, new Date())
}

export const getFullAddress = (address: Address): string => {
  if (!address) return '-'
  let result = `${address.addressStreet} ${address.addressBuildingNumber}`
  if (address.addressApartmentNumber)
    result += `/${address.addressApartmentNumber}`
  result += `, ${address.addressPostalCode} ${address.addressCity}`
  if (address.countryName) result += `, ${address.countryName}`
  return result
}

export const getPlaceDescription = (
  place: SamplingPoint | Laboratory
): string => {
  if (!place) return '-'
  const { address, name, phoneNumber } = place
  let result = name
  if (address) result += `, ${getFullAddress(address)}`
  result += `, nr tel: ${phoneNumber}`
  return result
}

export const declensionName = (
  length = 0,
  singularName: string,
  pluralNominativeName: string,
  pluralGenitiveName: string,
  valueFormatter = (value: number): number | string => value
) => {
  let label = singularName

  if (
    length % 10 < 5 &&
    length % 10 > 1 &&
    (length % 100 > 14 || length % 100 < 12)
  ) {
    label = pluralNominativeName
  } else if (length !== 1) {
    label = pluralGenitiveName
  }
  return `${valueFormatter(length)} ${label}`.trim()
}

export const parseDeadline = (amount: number, unit: string) => {
  switch (unit) {
    case 'hours':
      return declensionName(amount, 'godzina', 'godziny', 'godzin')
    case 'days':
      return declensionName(
        amount,
        'dzień roboczy',
        'dni robocze',
        'dni roboczych'
      )
    case 'months':
      return declensionName(amount, 'miesiąc', 'miesiące', 'miesiące')
    case 'years':
      return declensionName(amount, 'rok', 'lata', 'lat')
    default:
      return `${amount} ${unit}`
  }
}

export const toggleBodyOverflow = (disable: boolean) => {
  const body = document.querySelector('body')
  if (!body) return
  body.style.overflow = disable ? 'hidden' : 'visible'
}

export const getCategories = (categories: { id: number; name: string }[]) => {
  return categories.map((item) => item.name).join(', ') || '-'
}
export const getLabelsString = (items: { value: string; label: string }[]) => {
  return (
    items
      .map((item, i) => (i ? item.label.toLowerCase() : item.label))
      .join(', ') || '-'
  )
}
export const getAgeCategoriesString = (
  items: { value: string; label: string }[]
) => {
  if (items.length === 3) return 'Wszystkie'
  return getLabelsString(items)
}

export const convertToFloat = (val: number | string) => {
  if (!val) return null
  return parseFloat(Number(val.toString().replace(',', '.')).toFixed(2))
}

export const getSampleTypeIcon = (sampleType: string) => {
  switch (sampleType) {
    case 'blood':
      return {
        icon: 'syringe',
        color: 'additional2-60',
        bgColor: 'additional2-80',
      }
    case 'smear':
      return {
        icon: 'swab',
        color: 'secondary-60',
        bgColor: 'secondary-80',
      }
    default:
      return {
        icon: 'setoflabs',
        color: 'primary-60',
        bgColor: 'primary-90',
      }
  }
}
export const getExamTypeIcon = (exam: ExecutionMethod) => {
  switch (exam) {
    case 'at_home':
      return {
        icon: 'shipment',
        color: 'black',
        borderColor: 'neutral-90',
        bgColor: 'white',
      }
    case 'at_sampling_point':
      return {
        icon: 'pin-location',
        color: 'black',
        borderColor: 'neutral-90',
        bgColor: 'white',
      }
    default:
      return {
        icon: '',
        color: 'white',
        bgColor: 'white',
      }
  }
}

export const isExecMethodDifferent = (cart: Cart, execMethod: ExecutionMethod) => {
  const { cartItems } = cart
  if (!cartItems?.length) return false
  return cartItems[0].examination.executionMethod.value !== execMethod
}
export const areCartAppointmentsJoined = (cart: Cart, notNull = false) => {
  const { cartItems } = cart
  if (!cartItems?.length) return false

  const checkUniqueValues = (callback: (item: CartItem) => unknown) => {
    const valuesArray = Array.from(
      new Set(cartItems.map((item) => callback(item)))
    )

    const isOnlyValue = valuesArray.length == 1
    const includesEmpty = valuesArray.includes(null)

    if (notNull) {
      return isOnlyValue && !includesEmpty
    }

    return isOnlyValue
  }

  const examinationDatesCondition = checkUniqueValues(
    (item) => item.examinationDate
  )

  const samplingPointIdsCondition = checkUniqueValues(
    (item) => item.samplingPoint?.id
  )

  // check if all cart items have same date and sampling point
  return examinationDatesCondition && samplingPointIdsCondition
}

export const getBoundaryDates = (chosenDate: string, intervalLength = 6) => {
  const currentDate = new Date(chosenDate)
  const days = date.getDayOfWeek(currentDate) - 1
  const start = date.subtractFromDate(currentDate, { days })
  const end = date.addToDate(start, { days: intervalLength })

  return { start, end }
}

export const parseWeekday = (day: Date, format = 'D MMMM', shorten = false) => {
  const locale = shorten ? shortWeekdayLocale : weekdayLocale
  return date.formatDate(day, format, locale)
}

export const getWeekSpan = (chosenDate: string, intervalLength = 6, showYear = false) => {
  const { start, end } = getBoundaryDates(chosenDate, intervalLength)

  let startFormat = start.getMonth() === end.getMonth() ? 'D' : 'D MMMM'
  if (showYear && (start.getFullYear() !== end.getFullYear())) startFormat = 'D MMMM YYYY'
  const formattedStart = parseWeekday(start, startFormat)
  const formattedEnd = parseWeekday(end, showYear ? 'D MMMM YYYY' : 'D MMMM')
  return `${formattedStart} - ${formattedEnd}`
}

export const showNotification = (type: string, message: string) => {
  let icon
  switch (type) {
    case 'error':
      icon = 'error_outline'
      break
    case 'success':
      icon = 'check_circle_outline'
      break
    default:
      icon = 'info'
  }

  Notify.create({
    message: message,
    html: true,
    icon: icon,
    color: `${type}-80`,
    textColor: `${type}-60`,
    position: 'top',
    badgeColor: 'additional2-60'
  })
}

export const getCapacity = (value: number, unit: string) => {
  const leadOption = leadTimeOptions.find(item => item.value.value === value && item.value.unit === unit)
  return leadOption ? leadOption.value.capacity : '-'
}

export const getShipmentAddress = (method?: ShippingMethod | null, receiver?: ShipmentDetails | null) => {
  if (!method || !receiver) return '-'
  switch (method.shippingMethodType) {
    case 'courier':
      return getFullAddress(receiver.address as Address)
    case 'delivery_point':
      return receiver.deliveryPoint?.name
    default:
      return '-'
  }
}

export const convertCamelToSnake = (text: string) => {
  if (!text) return '-'
  return text.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
}

export const convertToAllowedUrlChar = (text: string) => {
  const allowedCharacters = text
    .trim()
    .toLowerCase()
    .replace(/ą/g, 'a')
    .replace(/ć/g, 'c')
    .replace(/ę/g, 'e')
    .replace(/ł/g, 'l')
    .replace(/ó/g, 'o')
    .replace(/ś/g, 's')
    .replace(/ź/g, 'z')
    .replace(/ż/g, 'z')
    .match(/[A-Za-z0-9_.\-~ ]/g)
  const finalUrl = allowedCharacters?.length ?
    allowedCharacters.join('').trim().replace(/ /g, '-') : ''
  return finalUrl
}

export const getEmbeddedUrl = (url: string | undefined | null) => {
  if (!url) return null
  const { id, service } = getVideoId(url);
  if (service === 'youtube') return `https://www.youtube.com/embed/${id}`;
  if (service === 'vimeo') return `https://player.vimeo.com/video/${id}`;
  return null;
}
