import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/logowanie',
    component: () => import('../../layouts/AdminAuthLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/lab/auth/Login.vue'),
        name: 'login',
        meta: {
          header: 'Zaloguj się',
          subheader: 'Zaloguj się do swojego konta Laboratorium.',
        },
      },
      {
        path: 'resetuj-haslo',
        component: () => import('pages/admin/auth/ResetPassword.vue'),
        name: 'reset-password',
        meta: {
          header: 'Resetuj hasło',
          subheader:
            'Wpisz adres e-mail na który wyślemy Ci link do resetu hasła.',
        },
      },
      {
        path: 'nowe-haslo/:token',
        component: () => import('pages/admin/auth/SetNewPassword.vue'),
        name: 'new-password',
        meta: {
          header: 'Utwórz nowe hasło',
          subheader:
            'Hasło musi zawierać minimum jedną dużą, jedną małą literę, cyfrę oraz znak specjalny.',
        },
      },
    ],
    meta: {
      title: 'Logowanie',
    },
  },
  {
    path: '/potwierdzenie-konta',
    component: () => import('../../layouts/AdminAuthLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/admin/auth/SetNewPassword.vue'),
        name: 'complete-account',
        meta: {
          header: 'Ustaw hasło',
          subheader:
            'Hasło musi zawierać minimum jedną dużą, jedną małą literę, cyfrę oraz znak specjalny.',
        },
      },
    ],
    meta: {
      title: 'Potwierdź konto',
    },
  },
  {
    path: '/strona-glowna',
    component: () => import('layouts/UserLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/admin/user/Dashboard.vue'),
        name: 'home',
      },
    ],
    meta: {
      title: 'Strona główna',
      requiresAuth: true,
    },
  },
  {
    path: '/wizyty',
    component: () => import('layouts/UserLayout.vue'),
    children: [
      {
        path: '',
        name: 'appointments',
        component: () => import('pages/admin/user/Appointments.vue'),
      },
      {
        path: 'szczegoly/:id',
        component: () => import('pages/admin/user/AppointmentDetails.vue'),
        name: 'appointment-details',
      },
    ],
    meta: {
      title: 'Lista wizyt',
      requiresAuth: true,
    },
  },
  {
    path: '/testy-wysylkowe',
    component: () => import('layouts/UserLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/admin/user/ShipmentTests.vue'),
        name: 'shipment',
      },
      {
        path: 'szczegoly/:id',
        component: () => import('pages/admin/user/ShipmentTestDetails.vue'),
        name: 'shipment-details',
      },
    ],
    meta: {
      title: 'Testy wysyłkowe',
      requiresAuth: true,
    },
  },
  {
    path: '/badania',
    component: () => import('layouts/UserLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/lab/user/Examinations.vue'),
        name: 'examinations',
      },
      {
        path: 'szczegoly/:id/:labId?',
        component: () => import('pages/admin/user/ExaminationDetails.vue'),
        name: 'examination-details'
      },
    ],
    meta: {
      title: 'Badania',
      requiresAuth: true,
      onlyAdminCanAccess: true,
    },
  },
  {
    path: '/punkty-pobran',
    component: () => import('../../layouts/UserLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/lab/user/SamplingPoints.vue'),
        name: 'sampling-points'
      },
      {
        path: 'szczegoly/:id',
        component: () => import('components/pages/samplingPoint/SamplingPointDetails.vue'),
        name: 'sampling-point-details'
      },
    ],
    meta: {
      title: 'Punkty obsługi pacjenta',
      requiresAuth: true,
      onlyAdminCanAccess: true
    },
  },
  {
    path: '/pacjenci',
    component: () => import('layouts/UserLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/admin/user/Patients.vue'),
        name: 'patients',
      },
      {
        path: 'szczegoly/:patientId',
        component: () => import('pages/admin/user/PatientDetails.vue'),
        name: 'patient-details',
      },
    ],
    meta: {
      title: 'Lista pacjentów',
      requiresAuth: true,
    },
  },
  {
    path: '/konto',
    component: () => import('../../layouts/UserLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/lab/user/Account.vue'),
        name: 'account'
      },
    ],
    meta: {
      title: 'Moje konto',
      requiresAuth: true,
    },
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'login' },
    meta: {
      title: '404',
    },
  },
]

export default routes
