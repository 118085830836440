import { api } from 'boot/axios'

export default {
  validateEmail(email: string) {
    return api.get('validations/email', { params: { email } })
  },
  validateIdentity(identityValue: string, identityType: string, gender: string, birthday: string) {
    const params = { identity: { identityType, identityValue, gender, birthday } }
    return api
      .get('validations/identity', { params })
  },
  validatePhone(phoneNumber: string) {
    return api
      .get('validations/phone_number', { params: { phoneNumber } })
  },
  validateExamination(params: { name?: string, shortName?: string, code?: string }) {
    return api
      .get('validations/examination', { params })
  }
}
