import { api } from 'boot/axios'
import { AuthPasswordForm } from '../types/auth'

export default {
  resetPassword(params: { email: string }) {
    return api.post('users/reset_password', params)
  },
  setPassword(params: AuthPasswordForm) {
    return api.post('users/set_password', params)
  },
  completeScientistAccount(params: AuthPasswordForm) {
    return api.post('scientists/me/complete_account', params)
  },
  deactivatePatient(id: number) {
    return api.patch(`patients/${id}/deactivate`)
  },
  reactivateAccount(id: number) {
    return api.patch(`patients/${id}/reactivate`)
  }
}
