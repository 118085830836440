import { api } from 'boot/axios'

export default {
  setupTotp() {
    return api.post('totp/setup')
  },
  enableTotp(totpAttempt: string) {
    return api.post('totp/enable', { totpAttempt })
  },
  validateTotp(totpAttempt: string) {
    return api.post('totp/validate', { totpAttempt })
  },
}
