import { api } from 'boot/axios'
import {
  CartUpdateParams,
  AvailableTimeSlotsParams,
  AvailableSamplingPoints,
  CartSubmit,
} from '../types/cart'

const getCartHeaders = () => ({
  headers: {
    'X-Order-Token': localStorage.getItem('INN-cart-token') as string,
  },
})

export default {
  addItemToCart(examinationId: number) {
    return api.post('/cart/items', { examinationId }, getCartHeaders())
  },
  deleteCartItem(examinationId: number) {
    return api.delete(`/cart/items/${examinationId}`, getCartHeaders())
  },
  clearCart() {
    return api.put('/cart/clear', {}, getCartHeaders())
  },
  updateCart(params: CartUpdateParams) {
    return api.put('/cart', params)
  },
  getCart() {
    return api.get('/cart', getCartHeaders())
  },
  submitOrder(cart: CartSubmit) {
    return api.post('/orders', cart)
  },
  getOrderSummary(params: { transactionId: string }) {
    return api.get('/orders/summary', { params, headers: {} })
  },
  retryPayment(orderId: number) {
    return api.post(`/orders/${orderId}/retry_payment`)
  },
  getAvailableTimeSlots(params: AvailableTimeSlotsParams) {
    return api.get('/cart/available_time_slots', { params, headers: {} })
  },
  getAvailableSamplingPoints(params: AvailableSamplingPoints) {
    return api.get('cart/available_sampling_points', { params, headers: {} })
  },
}
