import { route } from 'quasar/wrappers'
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router'
import storageVars from '../const/storageVariables'
import customerRoutes from './routes/customer'
import adminRoutes from './routes/admin'
import labRoutes from './routes/lab'
import { useCustomerAuthStore } from 'src/stores/customer/auth-store'
import { getAuthStore } from 'src/utils/functions'
import type { RouteLocationRaw } from 'vue-router'
import { useLabAuthStore } from 'stores/lab/auth-store'

const routes = () => {
  switch (import.meta.env.VITE_APP_TYPE) {
    case 'ADMIN':
      return adminRoutes
    case 'LAB':
      return labRoutes
    default: // CUSTOMER
      return customerRoutes
  }
}
/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : process.env.VUE_ROUTER_MODE === 'history'
      ? createWebHistory
      : createWebHashHistory

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes: routes(),

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE),
  })

  // Mechanism for keeping session alive in new tab
  window.addEventListener('storage', (event) => {
    const tokens = window.sessionStorage.getItem(storageVars.auth) as string

    // On initial capture store tokens to LS var only to trigger second capture with the same tokens as argument and remove this from LS afterwards
    if (event.key === 'REQUESTING_SHARED_CREDENTIALS' && tokens) {
      window.localStorage.setItem('TMP_CREDENTIALS', tokens)
      window.localStorage.removeItem('TMP_CREDENTIALS')
    }
    //  On the second capture store tokens to SS as it is in previous tab
    if (event.key === 'TMP_CREDENTIALS' && !tokens) {
      window.sessionStorage.setItem(storageVars.auth, event.newValue as string)
    }
  })
  // Trigger initial event capture
  window.localStorage.setItem(
    'REQUESTING_SHARED_CREDENTIALS',
    Date.now().toString()
  )
  window.localStorage.removeItem('REQUESTING_SHARED_CREDENTIALS')

  Router.beforeEach((to, from, next) => {
    const detailsPage = to.params?.id ? ': ' + to.params.id : ''
    document.title = to.meta.title + detailsPage + ' - Inngen' || 'Inngen'

    const authStore = getAuthStore()
    const customerAuthStore = useCustomerAuthStore()
    const tokenData =
      localStorage.getItem(storageVars.auth) ||
      sessionStorage.getItem(storageVars.auth)
    if (!authStore.isLoggedIn && tokenData) {
      authStore.setToken(JSON.parse(tokenData))
      if (import.meta.env.VITE_APP_TYPE === 'CUSTOMER')
        customerAuthStore.fetchUser()
    }

    if (to.name === 'confirm') {
      next({ name: 'login' })
      if (import.meta.env.VITE_APP_TYPE === 'CUSTOMER')
        customerAuthStore.confirmAccount(String(to.query.token))
    } else if (
      from.name !== 'login' &&
      to.meta.requiresAuth &&
      !authStore.isLoggedIn
    ) {
      next({ name: 'login', params: { redirect: to.name } } as RouteLocationRaw)
    } else if (to.name === 'login' && authStore.isLoggedIn) {
      next({ name: 'home' })
    } else if (
      import.meta.env.VITE_APP_TYPE === 'LAB' &&
      useLabAuthStore().userRole === 'worker' &&
      to.meta.onlyAdminCanAccess
    ) {
      next({ name: 'home' })
    } else {
      next()
    }
  })

  return Router
})
