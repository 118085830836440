<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useFiltersStore } from './stores/filters'
export default defineComponent({
  name: 'App',
  setup() {
    const filtersStore = useFiltersStore()
    filtersStore.fetchFilters()
  },
})
</script>
