import { api } from 'boot/axios'
import { PatientForm } from 'src/types/patients'
import {
  AuthRegisterIdentity,
  UpdateUserForm,
  ChangePasswordForm,
  AuthIdentity,
  AuthRegister,
} from 'src/types/auth'
import { ExecutionMethod } from 'src/types/examinations'

export default {
  fetchUser() {
    return api.get('/customers/me')
  },
  updateUser(data: UpdateUserForm) {
    return api.patch('/customers/me', data)
  },
  confirmCustomer(token: string) {
    return api.patch('/customers/confirm', { token })
  },
  resendConfirmation(id: number) {
    return api.patch(`customers/${id}/resend_confirmation_email`)
  },
  changePassword(data: ChangePasswordForm) {
    return api.patch('/customers/me/change_password', data)
  },
  getAppointments(params: {
    identityId: number
    executionMethod: ExecutionMethod
    page?: number
    itemsPerPage?: number
  }) {
    return api.get('/customers/me/tests', {
      params,
      headers: {},
    })
  },
  getOrders(params: { page?: number; itemsPerPage?: number }) {
    return api.get('/customers/me/orders', {
      params,
      headers: {},
    })
  },
  getResults(params: {
    identityId: number
    page?: number
    itemsPerPage?: number
  }) {
    return api.get('/customers/me/results', {
      params,
      headers: {},
    })
  },
  createCustomer(data: AuthRegister) {
    return api.post('/customers', data)
  },
  updateCustomer(id: number, form: PatientForm) {
    return api.patch(`/customers/${id}`, form)
  },
  deactivateAccount(identityId?: number) {
    return api.patch('customers/me/deactivate', { identityId })
  },

  // ----------------- CHILDREN ------------------

  createChild(identity: Omit<AuthIdentity, 'id'>) {
    return api.post('/children', { identity })
  },
  updateChild(id: number, form: { identity: AuthRegisterIdentity }) {
    return api.patch(`/children/${id}`, form)
  },
}
